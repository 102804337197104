class ImageLoadingObserver {
  constructor(configOptions = {}, callback) {
    this.options = {
      root: configOptions.root || null,
      rootMargin: configOptions.rootMargin || "0px",
      threshold: configOptions.threshold || 0.1
    };
    this.callback = callback;
    this.observer = new IntersectionObserver(this.handleIntersect, this.options);
  }

  observe = (targetElem) => {
    this.observer.observe(targetElem);
  };

  destroy = () => {
    this.observer.disconnect();
  };

  // loadImage = (url) =>
  //   new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.addEventListener("load", () => resolve(img));
  //     img.addEventListener("error", (err) => reject(err));
  //     img.src = url ? url : null;
  //   });

  // getImg = async (url) =>
  //   await this.loadImage(url)
  //     .then((el) => {
  //       return el.src;
  //     })
  //     .catch(() => {
  //       return null;
  //     });

  handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= this.options.threshold) {
        const imgArr = entry.target.querySelectorAll("img");

        // data-src is read from the wrapperElm so that the intersection observer does not need to read from props and can be cached

        for (let i = 0; i < imgArr.length; i++) {
          if (imgArr[i].getAttribute("data-imageobserver")) {
            const src = imgArr[i].getAttribute("data-src");
            const alt = imgArr[i].getAttribute("alt");
            if (src) {
              imgArr[i].src = src;
              if (alt === "source image") {
                imgArr[i].classList.remove("h-2", "w-2");
                imgArr[i].classList.add("h-5", "w-5");
                // img.className = "h-5 w-5 rounded-full object-cover";
              }
              // this.callback(img);
              this.observer.unobserve(entry.target);
            }
          }
        }
      }
    });
  };
}

export default ImageLoadingObserver;
