const { subscribeScrollInterSection, observeElement } = (() => {
  let toSync = {};
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.8],
  };
  let isScrolling = null;
  let isViewTimer = null;
  let observer;

  // idlecallback func
  let requestIdleCallback = () => {};
  if (typeof window === "object") {
    requestIdleCallback = window.requestIdleCallback
      ? window.requestIdleCallback
      : (cb) => {
          const start = Date.now();
          return setTimeout(() => {
            cb({
              didTimeout: false,
              timeRemaining() {
                return Math.max(0, 50 - (Date.now() - start));
              },
            });
          }, 1);
        };
  }

  // factory function for Visibility of elements
  function Visibility(target, isScrolled, isViewed) {
    this.elementIndex = target;
    this.isScrolled = isScrolled;
    this.isViewed = isViewed;
    this.userId = localStorage.getItem("id");
  }
  // Helper function to get value from path
  function get(obj, path, defaultValue) {
    const travel = (regexp) =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce(
          (res, key) => (res !== null && res !== undefined ? res[key] : res),
          obj
        );
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
  }

  // Observer callback
  function handleObserverCB(enteries, elementIdSelector, thresholdValue) {
    enteries.forEach((entry) => {
      const elementId = get(entry, `target.${elementIdSelector}`);
      const { index } = entry.target.dataset;
      let isScrolled = true;

      if (!entry.target.initialSetup) {
        // eslint-disable-next-line no-param-reassign
        entry.target.initialSetup = true;
        isScrolled = entry.isIntersecting || entry.intersectionRatio > 0;
      }

      toSync[elementId] = new Visibility(
        index,
        isScrolled,
        entry.isIntersecting && entry.intersectionRatio >= thresholdValue
      );
    });
  }

  function generatePayload() {
    let status = false;
    const payload = {
      scrolled: {},
      paused_records: {},
    };

    Object.keys(toSync).forEach((key) => {
      status = toSync[key].isViewed;
      if (status) {
        payload.paused_records[key] = {
          elementIndex: toSync[key].elementIndex,
          viewed: status,
          userId: get(toSync[key], "userId"),
        };
      }

      // all records are by default scrolled if they are viewed
      if (toSync[key].isScrolled) {
        payload.scrolled[key] = {
          elementIndex: toSync[key].elementIndex,
          viewed: false,
          userId: get(toSync[key], "userId"),
        };
      }
    });
    // make an empty before sending current snapshot
    toSync = {};
    return payload;
  }

  function clearViewTimer() {
    if (isViewTimer) {
      window.clearTimeout(isViewTimer);
      isViewTimer = null;
    }
  }
  function clearScrollTimer() {
    if (isScrolling) {
      window.clearTimeout(isScrolling);
      isScrolling = null;
    }
  }

  function pausedStateCallBack(timeInterval, subscriberCallBack, payload) {
    clearViewTimer();
    isViewTimer = setTimeout(
      subscriberCallBack.bind(null, payload),
      timeInterval
    );
  }

  function eventsAfterScrollStop(timeInterval, subscriberCallBack) {
    const payload = generatePayload();
    pausedStateCallBack(
      timeInterval,
      subscriberCallBack,
      payload.paused_records
    );
    subscriberCallBack(payload.scrolled); // for scrolled Item
  }

  function scrollStop(callback, timeInterval) {
    clearScrollTimer();
    isScrolling = setTimeout(callback, timeInterval);
  }

  // scrollListener
  function scrollListener(event, timeInterval, subscriberCallBack) {
    scrollStop(
      eventsAfterScrollStop.bind(null, timeInterval, subscriberCallBack),
      timeInterval
    );
  }

  // add observer one by one
  function _observeElement(element) {
    if (element && observer) {
      observer.observe(element);
      return () => {
        clearScrollTimer();
        clearViewTimer();
        observer.unobserve(element);
      };
    }

    return undefined;
  }

  /**
   * @param {*} elementGroup : array of Elements
   * @param {*} timeInterval : time in which user can read/view the element
   * @param {*} elementIdSelector : unique identifier in elements
   * @param {*} thresholdValue : threshHoldvalue defines visibility % of element in viewport must be b/w 0 - 1
   * @param {function(*=): void} callback : final callback will be triggered after scroll
   */
  function _subscribeScrollInterSection(
    elementGroup,
    timeInterval,
    elementIdSelector,
    thresholdValue,
    callback,
    scrollingEl
  ) {
    toSync = {};
    observer = new IntersectionObserver((enteries) => {
      handleObserverCB(enteries, elementIdSelector, thresholdValue);
    }, options);

    if (elementGroup && elementGroup.length)
      elementGroup.forEach((v) => observer.observe(v));

    const listener = (event) => {
      requestIdleCallback(
        scrollListener.bind(null, event, timeInterval, callback)
      );
    };

    if (scrollingEl) {
      scrollingEl.addEventListener("scroll", listener, false);
    } else {
      window.addEventListener("scroll", listener, false);
    }

    // for initial page view/scroll status
    scrollListener(null, timeInterval, callback);

    // to remove listeners and disconnect observer
    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", listener);
    };
  }

  return {
    subscribeScrollInterSection: _subscribeScrollInterSection,
    observeElement: _observeElement,
  };
})();

export { subscribeScrollInterSection, observeElement };
