// import dayjs from "dayjs";
// import updateLocale from "dayjs/plugin/updateLocale";
// import relativeTime from "dayjs/plugin/relativeTime";

// dayjs.extend(relativeTime);
// dayjs.extend(updateLocale);

// dayjs.updateLocale("en", {
//   relativeTime: {
//     future: "%s ",
//     past: "%s ago ",
//     s: "%d s ",
//     m: "%d m ",
//     mm: "%d mins ",
//     h: "%d h ",
//     hh: "%d h ",
//     d: " d ",
//     dd: "%dds ",
//     M: " mon ",
//     MM: "%d mons ",
//     y: "yr ",
//     yy: "%dyrs ",
//   },
// });

// export const elapsedTimeWithLongFormat = (date) => {
//   const diffInMinutes = dayjs().diff(date, "m");
//   const diffInHours = dayjs().diff(date, "h");
//   const diffInDays = dayjs().diff(date, "d");
//   const diffInWeeks = dayjs().diff(date, "w");
//   const diffInMonths = dayjs().diff(date, "M");
//   const diffInYears = dayjs().diff(date, "y");
//   if (diffInMinutes > 0 && diffInMinutes < 60) {
//     return diffInMinutes + " mins ago";
//   }
//   if (diffInHours < 24 && diffInHours > 0) {
//     return diffInHours + " hours ago";
//   } else if (diffInHours >= 24 && diffInDays <= 7) {
//     if (diffInHours === 24 || diffInDays === 1) {
//       return "1 day ago";
//     }
//     return diffInDays + " days ago";
//   } else if (diffInDays > 7 && diffInWeeks <= 4) {
//     if (diffInWeeks === 1) {
//       return "1 week ago";
//     }
//     return diffInWeeks + " weeks ago";
//   } else if (diffInWeeks > 4 && diffInMonths <= 12) {
//     if (diffInMonths === 1) {
//       return "1 Month ago";
//     }
//     return diffInMonths + " months ago";
//   } else if (diffInMonths > 12 && diffInYears > 0) {
//     if (diffInYears === 1) {
//       return "1 year ago";
//     }
//     return diffInYears + " years ago";
//   } else {
//     return " just now";
//   }
// };

// export const elapsedTime = (date) => {
//   const diffInMinutes = dayjs().diff(date, "m");
//   const diffInHours = dayjs().diff(date, "h");
//   const diffInDays = dayjs().diff(date, "d");
//   const diffInWeeks = dayjs().diff(date, "w");
//   const diffInMonths = dayjs().diff(date, "M");
//   const diffInYears = dayjs().diff(date, "y");
//   if (diffInMinutes > 0 && diffInMinutes < 60) {
//     return diffInMinutes + "m";
//   } else if (diffInHours < 24 && diffInHours > 0) {
//     return diffInHours + "h";
//   } else if (diffInHours >= 24 && diffInDays <= 7) {
//     return diffInDays + "d";
//   } else if (diffInDays > 7 && diffInWeeks <= 4) {
//     return diffInWeeks + "w";
//   } else if (diffInWeeks > 4 && diffInMonths <= 12) {
//     return diffInMonths + "M";
//   } else if (diffInMonths > 12 && diffInYears > 0) {
//     return diffInYears + "y";
//   } else {
//     return "just now";
//   }
// };

const timeUnits = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  week: 24 * 60 * 60 * 1000 * 7,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
};

const rtf = new Intl.RelativeTimeFormat("en", { numeric: "always" });

const getRelativeTime = (d1, d2, unit) => {
  const elapsed = Math.abs(d2 - d1);
  if (elapsed > timeUnits[unit] || unit == "second") {
    const parts = rtf.formatToParts(Math.floor(elapsed / timeUnits[unit]), unit);
    if ((parts || []).length > 0) {
      const timeDifference = parts.reduce((total, obj) => {
        if (obj.type === "integer") {
          return total + obj.value;
        }
        return total;
      }, "");
      return +timeDifference;
    }
  }
  return 0;
};

export const elapsedTime = (date) => {
  const theDate = +new Date(date);
  const todayDate = +new Date();
  const diffInMinutes = getRelativeTime(theDate, todayDate, "minute");

  if (diffInMinutes < 1) {
    return "just now";
  }

  if (diffInMinutes > 0 && diffInMinutes < 60) {
    return diffInMinutes + "m";
  }

  const diffInHours = getRelativeTime(theDate, todayDate, "hour");

  if (diffInHours < 24 && diffInHours > 0) {
    return diffInHours + "h";
  }

  const diffInDays = getRelativeTime(theDate, todayDate, "day");

  if (diffInHours >= 24 && diffInDays <= 7) {
    return diffInDays + "d";
  }

  const diffInWeeks = getRelativeTime(theDate, todayDate, "week");

  if (diffInDays > 7 && diffInWeeks <= 4) {
    return diffInWeeks + "w";
  }

  const diffInMonths = getRelativeTime(theDate, todayDate, "month");

  if (diffInWeeks > 4 && diffInMonths <= 12) {
    return diffInMonths + "M";
  }

  const diffInYears = getRelativeTime(theDate, todayDate, "year");

  if (diffInMonths > 12 && diffInYears > 0) {
    return diffInYears + "y";
  }

  return "just now";
};
