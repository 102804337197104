/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import VideoArticleObserver from "@utils/videoArticleCardObserver";
import Imageplay from "@assets/icons/play1.png";
import ImageMute from "@assets/icons/mute.png";
import ImageUnmute from "@assets/icons/unmute.png";
import * as clickhouse from "./ch-events";
import firestoreServices from "@firestoreServices/firestoreServices";
import ImgLike from "@assets/icons/like-white.png";
import ImgLiked from "@assets/icons/liked.png";
import ImgWhatsapp from "@assets/icons/whatsapp.png";
import ImgPlay from "@assets/icons/play-green.png";
import http from "@apiServices/http";
import urls from "@apiServices/apiUrls";
import isMobileOrTablet from "@utils/isMobileOrTablet";
import objectToGetParams from "@utils/objectToGetParams";
import apiUrls from "@apiServices/apiUrls";
import kFormatter from "@utils/countUtil";
import { elapsedTime } from "@utils/getDiffInDates";
import Timer from "@utils/timer";
import s from "./videoArticle.module.scss";

const VideoArticle = ({
  articleData,
  index,
  userLikeArticles,
  className = "",
  page = "",
  slug = "",
  googleWebStories = ""
}) => {
  const [play, setPlay] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [muted, setMuted] = useState(true);
  const [isLikeCountUpdating, setIsLikeCountUpdating] = useState(false);
  const [isUserLikeArticle, setIsUserLikeArticle] = useState(
    userLikeArticles?.includes(articleData.id) ? true : false
  );
  const [buffering, setBuffering] = useState(false);
  const [likesCount, setLikesCount] = useState(articleData.likeCount);
  const [showVideoControl, setShowVideoControl] = useState(false);
  let titleElTimer = null;
  let titleBgElTimer = null;

  const videoCardRef = useRef(null);
  let vid = useRef<HTMLVideoElement>(null);

  // To pause and reset the video if the google web stories widget is clicked
  useEffect(() => {
    if (googleWebStories) {
      vid?.current?.pause();
      vid.current.currentTime = 0;
    } else {
      vid?.current?.play().catch(() => {});
    }
  }, [googleWebStories]);

  useEffect(() => {
    // in viewport timer
    const timer = new Timer();
    let timeInSeconds = 0;
    let timerInterval = null;

    const videoObserver = new VideoArticleObserver(
      {
        threshold: 0.9
      },
      (id) => {
        //video
        vid?.current?.load();
        // in viewport timer start
        timer.start();
        timerInterval = setInterval(() => {
          timeInSeconds = Math.round(timer.getTime() / 1000);
        }, 100);
        sessionStorage.setItem("video_playing", id);
        if (
          !showVideoControl &&
          articleData.id === id &&
          sessionStorage.getItem("video_playing") === articleData.id
        ) {
          setPlay(!play);
          vid?.current?.play().catch(() => {});
          const titleEl = document.getElementById(`videoArticleTitle-${id}`);
          const titleBgEl = document.getElementById(`videoArticleTitleOverlay-${id}`);
          titleEl.classList.add("fadein");
          titleBgEl.classList.add("fadein");
          titleElTimer = setTimeout(() => {
            titleEl.classList.add("fadeout");
            titleBgEl.classList.add("fadeout");
          }, 4000);
          titleBgElTimer = setTimeout(() => {
            titleEl.classList.add("invisible");
            titleBgEl.classList.add("invisible");
          }, 6000);
        }
      },
      () => {
        vid?.current?.pause();
        // in viewport timer stop and reset
        timer.stop();
        timer.reset();
        clickhouse.trackViewportVideoArticle({
          element_name: "video_article_card",
          element_type: "card",
          eventCategory: "video_unload",
          data: {
            article_id: articleData.id,
            video_playing_duration: vid?.current?.currentTime * 1000,
            in_viewport_duration: timeInSeconds * 1000,
            video_total_duration: vid?.current?.duration * 1000
          }
        });
        clearInterval(timerInterval);
        // reset the video
        if (vid.current) {
          vid.current.currentTime = 0;
        }
      }
    );

    if (videoObserver && videoCardRef && videoCardRef.current) {
      videoObserver.observe(videoCardRef.current);
      return () => {
        videoObserver.destroy();
        clearTimeout(titleElTimer);
        clearTimeout(titleBgElTimer);
        clearInterval(timerInterval);
      };
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    setIsUserLikeArticle(userLikeArticles?.includes(articleData.id) ? true : false);
  }, [userLikeArticles]);

  useEffect(() => {
    // vid.current.load();
    setMounted(true);
  }, []);

  // handling multiple videos playing in the video
  useEffect(() => {
    vid.current.onplaying = () => {
      sessionStorage.setItem("video_playing", vid?.current?.dataset?.id);
      setBuffering(false);
    };

    vid.current.onwaiting = function () {
      setBuffering(true);
    };
  });

  const handleLikeArticle = async () => {
    setIsUserLikeArticle(true);
    setIsLikeCountUpdating(true);
    setLikesCount(likesCount + 1);
    const firestore = new firestoreServices();

    clickhouse.trackArticleCTAClick({
      element_name: "video_article_card_like_icon",
      data: { article_id: articleData?.id, state: "select" }
    });

    await firestore.addUserArticlesLike(articleData?.id);
    http
      .post(urls.likeArticle({ articleId: articleData?.id }), {})
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLikeCountUpdating(false);
      });
  };

  const handleUnLikeArticle = async () => {
    setIsUserLikeArticle(false);
    setIsLikeCountUpdating(true);
    setLikesCount(likesCount - 1);
    const firestore = new firestoreServices();

    clickhouse.trackArticleCTAClick({
      element_name: "video_article_card_unlike_icon",
      data: { article_id: articleData?.id, state: "unselect" }
    });

    await firestore.deleteUserArticlesLike(articleData?.id);
    http
      .post(urls.unlikeArticle({ articleId: articleData?.id }), {})
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLikeCountUpdating(false);
      });
  };
  return (
    <div className={`${className} border-b-[3px] border-P100 pb-3 dark:border-DG700`}>
      <div className="mb-2 flex items-center justify-between px-5">
        <div className="rounded-2xl border-[1px] border-[#ebebeb] pr-1 dark:border-DG700">
          <div className="flex items-center justify-between">
            <div className="mr-[0.4375rem] flex">
              <img
                src={articleData?.sourceLogo}
                width={20}
                height={20}
                className="flex h-5 w-5 items-center justify-center rounded-full bg-N200 object-cover dark:bg-DN200"
                alt="source image"
              />
            </div>
            <p
              className={
                "font-normal text-readingTextSmall leading-readingTextSmall text-N500 dark:text-DN500"
              }
            >
              {articleData?.publishedDate ? (
                <div className="flex items-center">
                  <p className="mr-1">{articleData?.sourceName}</p>
                  <div className="h-[20px] border-l-[1px] border-[#ebebeb] pl-1 dark:border-DG700" />
                  <div className="">{elapsedTime(articleData.publishedDate)}</div>
                </div>
              ) : (
                `${articleData?.sourceName}`
              )}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          {articleData.readCount > 0 ? (
            <div className="flex items-center">
              <img src={ImgPlay} width={16} height={16} className="mr-1 h-4 w-4" alt="read" />
              <p className="text-readingTextMedium font-medium leading-readingTextMedium text-N400">
                {kFormatter(articleData.readCount)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="relative bg-DN100 pb-[1px]"
        ref={videoCardRef}
        data-type={"video"}
        data-newsbitetype={articleData.newsBiteType}
        data-id={articleData.id}
        onClick={() => {
          const titleEl = document.getElementById(`videoArticleTitle-${articleData.id}`);
          const titleBgEl = document.getElementById(`videoArticleTitleOverlay-${articleData.id}`);
          titleEl.classList.add("hidden");
          titleBgEl.classList.add("hidden");
          if (play) {
            vid?.current?.pause();
            setShowVideoControl(true);
            setPlay(false);
            setBuffering(false);
            clickhouse.trackCTAs({
              element_name: "pause_video_article",
              element_type: "icon",
              eventCategory: "click",
              data: {
                article_id: articleData.id,
                video_paused_at: vid?.current?.currentTime * 1000
              }
            });
          } else {
            if (sessionStorage.getItem("video_playing") === articleData.id) {
              setShowVideoControl(false);
              vid?.current?.play().catch(() => {});
              setPlay(true);
              clickhouse.trackCTAs({
                element_name: "play_video_article",
                element_type: "icon",
                eventCategory: "click",
                data: {
                  article_id: articleData.id,
                  video_played_at: vid?.current?.currentTime * 1000
                }
              });
            }
          }
        }}
      >
        {buffering ? (
          <div className="absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center">
            <div className={s.center}>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
              <div className={s.circle}>
                <div></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="absolute top-0 left-0 z-[10] h-full w-full"></div>
        <div
          className="absolute right-[16px] bottom-[49px] z-[20] flex flex-col items-center justify-center rounded-md bg-[#00000080] px-2 py-3"
          onClick={(e) => e.stopPropagation()}
        >
          {isUserLikeArticle ? (
            <div
              className=""
              onClick={() => {
                if (!isLikeCountUpdating) handleUnLikeArticle();
              }}
            >
              <img src={ImgLiked} width={24} height={24} className="h-6 w-6" alt="like" />{" "}
              <p
                className={`${"font-medium text-N100"} mt-[2px] text-center text-readingTextMedium leading-readingTextMedium`}
              >
                {likesCount}
              </p>
            </div>
          ) : (
            <div
              className=""
              onClick={() => {
                if (!isLikeCountUpdating) handleLikeArticle();
              }}
            >
              <img src={ImgLike} width={24} height={24} className="h-6 w-6" alt="like" />

              <p
                className={`${"font-medium text-N100"} mt-[2px] text-center text-readingTextMedium leading-readingTextMedium`}
              >
                {likesCount}
              </p>
            </div>
          )}

          <div
            className="mt-3"
            onClick={async () => {
              clickhouse.trackArticleCTAClick({
                pageType: page === "news_details" ? page : undefined,
                screenName: page === "news_details" ? page : "",
                element_name: "video_article_card_share_icon",
                data: {
                  article_id: articleData?.id,
                  slug: page === "news_details" ? slug : undefined
                }
              });

              const shareCountUrl = apiUrls.shareCount({ articleId: articleData.id });
              await http.post(shareCountUrl, {});
            }}
          >
            <a
              href={
                "https://" +
                (mounted && isMobileOrTablet() ? "api" : "web") +
                ".whatsapp.com/send" +
                objectToGetParams({
                  text: `${articleData.title} \n\nvia Techminis - Your daily dose of tech\n\n${articleData.shortUrl}`
                })
              }
              data-action="share/whatsapp"
              target="_blank"
              rel="noreferrer"
            >
              <img src={ImgWhatsapp} width={24} height={24} className="h-6 w-6" alt="share" />

              <p className="mt-[2px] text-center text-readingTextMedium leading-readingTextMedium text-[#40C057]">
                {articleData.shareCount}
              </p>
            </a>
          </div>
        </div>

        {!play && (
          <>
            <div className="absolute top-0 left-0 z-[10] h-full w-full"></div>
            <div className=" absolute top-[0] right-[0] z-[25] flex h-full w-full items-center justify-center bg-[#12121299]">
              <img src={Imageplay} width="94px" className="opacity-[.9]" alt="play" />
            </div>
          </>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setMuted(!muted);
          }}
          className="absolute right-[25px] bottom-[16px] z-[20]"
        >
          {!muted ? (
            <img
              src={ImageUnmute}
              alt="unmute"
              width="20px"
              height="20px"
              onClick={() => {
                clickhouse.trackCTAs({
                  element_name: "mute_video_article",
                  element_type: "icon",
                  eventCategory: "click",
                  data: {
                    article_id: articleData.id,
                    video_muted_at: vid.current.currentTime * 1000
                  }
                });
              }}
            />
          ) : (
            <img
              src={ImageMute}
              alt="mute"
              width="20px"
              height="20px"
              onClick={() => {
                clickhouse.trackCTAs({
                  element_name: "unmute_video_article",
                  element_type: "icon",
                  eventCategory: "click",
                  data: {
                    article_id: articleData.id,
                    video_unmuted_at: vid?.current?.currentTime * 1000
                  }
                });
              }}
            />
          )}
        </div>
        <video
          width="100%"
          ref={vid}
          controls={false}
          muted={muted}
          // autoPlay
          style={{ height: "60vh" }}
          loop
          preload="auto"
          playsInline
          // poster="/loading.svg"
          data-id={articleData.id}
        >
          <source src={articleData.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="visible absolute left-0 top-0 z-[20] h-full w-full cursor-default bg-[#000] opacity-[0.7]"
          id={`videoArticleTitleOverlay-${articleData.id}`}
          style={{ transition: "visibility 0s linear 0.33s, opacity 0.33s linear" }}
        ></div>
        <div
          className="absolute bottom-1 z-[25] flex h-full items-center"
          id={`videoArticleTitle-${articleData.id}`}
        >
          <p className="px-5 text-center text-titleL font-semiBold leading-titleL text-[#fff] line-clamp-3">
            {articleData.title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoArticle;
