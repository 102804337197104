import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackPageLoad = () => {
  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_load`);
};

export const trackVideoArticleVisible = ({ data }) => {
  let pathname = window.location.pathname.split("/");
  let page = getPageName();

  page = pathname[pathname.length - 1];

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    if (queryParams?.value) {
      page = `${page}_listing`;
    }
  }

  const payload = compact({
    eventCategory: "video_article_visible",
    screenName: `${page}`,
    ...data
  });
  trackCHEvents(`${page}_page_video_article_card_visible`, payload);
};

export const trackViewportVideoArticle = ({
  element_name,
  eventCategory,
  element_type,
  data = {},
  actionType = "click",
  pageSection = ""
}) => {
  const pageName = getPageName();

  const payload = compact({
    element_name,
    element_type,
    eventCategory,
    action_type: actionType,
    screenName: pageName,
    pageSection,
    ...data
  });

  trackCHEvents(`${pageName}_page_video_article_card_unload`, payload);
};

export const trackCTAs = ({
  element_name,
  eventCategory,
  element_type,
  data = {},
  actionType = "click",
  pageSection = ""
}) => {
  let page = getPageName();
  // page =
  //   window.location.pathname.split("/").length && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";

  const payload = compact({
    element_name,
    element_type,
    eventCategory,
    action_type: actionType,
    screenName: page,
    pageSection,
    ...data
  });

  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_${element_name}_${actionType}`, payload);
};

export const trackArticleCTAClick = ({
  element_name,
  data,
  screenName = "",
  pageType = undefined
}) => {
  // let page = window.location.pathname.split("/")
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "trending";

  let page = getPageName();

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    element_name,
    element_type: "icon",
    action_type: "click",
    screenName: screenName ? screenName : page,
    ...data
  });

  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};
