import { feedPageSize } from "@components/pages/feedPage/constants";
import { apiBase, apiSeoBase, apiV3Base } from "./urlConfig";

const urls = {
  suggestions: `${apiBase}/preferences`,
  feed: `${apiBase}/feed/summaries`,
  // trending: `${apiBase}/feed/summaries/trending`,
  trending: `${apiV3Base}/feed/summaries/trending`,
  trendingSSR: `${apiV3Base}/feed/top?size=${feedPageSize}`,
  noContentFeed: ({ slug }) => `${apiSeoBase}/seo/noContent?slug=${slug}`,
  readingList: `${apiBase}/feed/readingList`,
  search: ({ input = "" }) => `${apiBase}/feed/search?s=${input}`,
  getArticlesDetail: ({ ids }) => `${apiSeoBase}/feed/getNewsViaIds?newsIds=${ids}`,
  searchWithPagination: ({ input = "", page = 0, size = 20 }) =>
    `${apiBase}/feed/searchWithPagination?s=${input}&page=${page}&size=${size}`,
  searchSeoPagination: ({ input = "", page = 0, size = 20 }) =>
    `${apiSeoBase}/seo/v2/search/${input}?page=${page}&size=${size}`,
  searchSeoTag: ({ id = "", page = 0, size = 20 }) =>
    `${apiSeoBase}/seo/v2/tag/${id}?page=${page}&size=${size}`,
  searchSlugDetails: (slug = "") => `${apiSeoBase}/seo/${slug}/slugDetails`,
  getArticle: ({ articleId = "", slug = "" }) =>
    `${apiSeoBase}/seo/article/${articleId}/slug/${slug}`,
  likeArticle: ({ articleId }) => `${apiBase}/article/${articleId}/like`,
  unlikeArticle: ({ articleId }) => `${apiBase}/article/${articleId}/unlike`,
  discoverMoreTags: ({ tagIds }) => `${apiBase}/preferences/discover?tags=${tagIds}`,
  trendingDiscoverMoreTags: `${apiSeoBase}/seo/trendingSlugs`,
  reportArticle: ({ articleId, reason }) =>
    `${apiBase}/article/${articleId}/report?reason=${reason}`,
  notInterestedArticle: ({ articleId, reason }) =>
    `${apiBase}/article/${articleId}/notInterested?reason=${reason}`,
  trendingArticles: `${apiBase}/article/trending`,
  featured: `${apiBase}/article/featured`,
  stories: ({ tags }) =>
    tags
      ? `${apiBase}/feed/stories?tags=${tags}`
      : `${apiBase}/feed/stories?preferencesSkipped=true`,
  trendingTopics: ({ tags = "" }) => `${apiBase}/preferences/trendingTags?tags=${tags}`,
  exploreDetailPage: ({ articleId, tagId, page = 0, size = 20 }) =>
    `${apiBase}/feed/tags/${tagId}?page=${page}&size=${size}&articleId=${articleId}`,
  markAsSeen: ({ articleId }) => `${apiBase}/article/${articleId}/seen`,
  readUrl: ({ articleId }) => `${apiBase}/article/${articleId}/incrReadCount`,
  shareCount: ({ articleId }) => `${apiBase}/article/${articleId}/incrShareCount`,
  signUp: ({ email }) => `${apiBase}/user/preference/email?email=${email}`,
  pollingUrl: `${apiBase}/feed/summaries/new`,
  pollingTrendingUrl: `${apiBase}/feed/summaries/trending/new`,
  getInTouch: `${apiBase}/feedback`,
  googleWebStories: `${apiBase}/stories`,
  googleWebStorySeen: (id) => `${apiBase}/story/${id}/seen`
};

export default urls;
