import { useCallback, useEffect, useRef } from "react";
export default function useFallbackImageInSSR(fallbackSrc) {
  const ref = useRef(null);

  /**
   * Error happened / catched after hydration
   */
  const onError = useCallback(
    (e) => {
      const img = e.target;
      img.src = fallbackSrc;
      if (img.alt === "source image") {
        img.classList.remove("h-5", "w-5");
        img.classList.add("h-2", "w-2");
      }
    },
    [fallbackSrc]
  );

  /**
   * Error happened before hydration, but catched after hydration
   */
  useEffect(() => {
    if (ref && ref.current) {
      const { complete, naturalHeight } = ref.current;
      const errorLoadingImgBeforeHydration = complete && naturalHeight === 0;

      if (errorLoadingImgBeforeHydration) {
        const img = ref.current;
        img.src = fallbackSrc;
        if (img.alt === "source image") {
          img.classList.remove("h-5", "w-5");
          img.classList.add("h-2", "w-2");
        }
      }
    }
  }, [fallbackSrc]);

  return {
    ref,
    onError
  };
}
