import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackPageLoad = () => {
  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_load`);
};

export const trackFeedsPageLoad = () => {
  // const urlSearchParams = new URLSearchParams(window?.location?.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // const data = params;
  let page = getPageName();

  const payload = compact({
    screenName: page
  });

  // if (params?.mode) {
  //   trackCHEvents(`splash_page_load`, payload);
  // } else {
  trackCHEvents(`${page}_page_load`, payload);
  // }
};

export const trackArticleCardClick = ({ element_name, data }) => {
  // page =
  //   window.location.pathname.split("/") && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";
  const payload = compact({
    element_name,
    element_type: "card",
    action_type: "click",
    ...data
  });
  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_${element_name}_click`, payload);
};

export const trackArticleSeen = ({ pageType, data }) => {
  // page =
  //   window.location.pathname.split("/") && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";
  let page = getPageName();
  const payload = compact({
    eventCategory: "article_view",
    screenName: pageType ? pageType : page,
    ...data
  });
  trackCHEvents(`${pageType ? pageType : page}_page_article_view`, payload);
};
export const trackVisibleCard = ({
  eventName,
  eventCategory,
  articleId = undefined,
  screenName,
  data = undefined
}) => {
  const updatedData = articleId ? { article_id: articleId, ...data } : data;
  let page = getPageName();
  // page =
  //   window.location.pathname.split("/") && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";
  const payload = compact({
    eventCategory,
    screenName: screenName ? screenName : page,
    eventName,
    ...updatedData
  });

  trackCHEvents(`${eventName}`, payload);
};

export const trackArticleVisible = (params = {}) => {
  let pathname = window.location.pathname.split("/");
  let page = getPageName();
  if (params.page) {
    page = params.page;
  } else {
    // if (pathname.length >= 3) {
    //   page = pathname[2];
    // } else {
    //   page = pathname[1];
    // }

    page = pathname[pathname.length - 1];
  }
  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    if (queryParams?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    eventCategory: "article_visible",
    screenName: `${page}`,
    ...params.data
  });
  trackCHEvents(`${page}_page_article_visible`, payload);
};

export const trackBackToTopClick = ({ element_name }) => {
  const payload = compact({
    element_name,
    element_type: "card",
    action_type: "click"
  });
  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_${element_name}_button`, payload);
};

export const trackNewArticleChickletClick = ({ element_name }) => {
  const payload = compact({
    element_name,
    element_type: "chicklet",
    action_type: "click"
  });
  const page = getPageName();
  trackCHEvents(`${page}_page_${element_name}_click`, payload);
};

export const trackCTAs = ({
  element_name,
  eventCategory,
  element_type,
  data = {},
  actionType = "click",
  pageSection = ""
}) => {
  let page = getPageName();
  // page =
  //   window.location.pathname.split("/").length && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";

  const payload = compact({
    element_name,
    element_type,
    eventCategory,
    action_type: actionType,
    screenName: page,
    pageSection,
    ...data
  });

  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_${element_name}_${actionType}`, payload);
};

export const trackStoryView = ({
  element_name,
  eventCategory,
  element_type,
  data = {},
  actionType = "view",
  pageSection = ""
}) => {
  let page = getPageName();
  // page =
  //   window.location.pathname.split("/").length && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";

  const payload = compact({
    element_name,
    element_type,
    eventCategory,
    action_type: actionType,
    screenName: page,
    pageSection,
    ...data
  });

  const pageName = getPageName();
  trackCHEvents(`${pageName}_page_${element_name}_view`, payload);
};
