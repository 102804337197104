import cx from "classnames";

type Props = {
  mounted?: boolean;
  resolvedTheme: string;
  darkSrc: string;
  lightSrc: string;
  dataSrc?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  onClick?: any;
  onError?: any;
  lazyLoad?: boolean;
  dataImageObserver?: boolean;
};

const ThemedImage = ({
  mounted = false,
  resolvedTheme = "light",
  darkSrc,
  lightSrc,
  dataSrc,
  alt,
  onClick = () => {},
  width,
  height,
  className,
  onError,
  lazyLoad = false,
  dataImageObserver = false
}: Props) => {
  const src = mounted ? (resolvedTheme === "dark" ? darkSrc : lightSrc) : lightSrc;
  return (
    <img
      className={cx(className)}
      src={src}
      data-src={dataSrc}
      data-imageobserver={dataImageObserver}
      alt={alt}
      onClick={onClick}
      width={width || 24}
      height={height || 24}
      onError={onError}
      loading={lazyLoad ? "lazy" : "eager"}
    />
  );
};

export default ThemedImage;
