import React, { ReactNode } from "react";
import cx from "classnames";

type ChipProps = {
  size?: "regular" | "small";
  children: ReactNode;
  containerClassName?: string;
  disabled?: boolean;
  selected?: boolean;
};

const DEFAULT_CHIP_CONFIG = {
  regular: "text-readingTextMedium leading-readingTextMedium px-3 py-2",
  small: "text-readingTextSmall leading-readingTextSmall px-2 py-1",
};

const Chip = ({
  size = "regular",
  children,
  containerClassName,
  disabled = false,
  selected,
}: ChipProps) => {
  return (
    <div
      className={cx(
        containerClassName,
        DEFAULT_CHIP_CONFIG[size],
        `${
          disabled
            ? "dark:text-DN600 text-N400 dark:bg-DN200 bg-N200 pointer-events-none cursor-default border-none"
            : ""
        }`,
        `${
          selected
            ? "!text-P500 bg-P050 dark:border-DP100 border-P100 font-medium dark:bg-[#0F1C38]"
            : ""
        }`,
        "text-N600 dark:text-DN600 bg-N100 dark:bg-DN050 border-N300 dark:border-DN300 cursor-pointer rounded border border-solid"
      )}
    >
      {children}
    </div>
  );
};

export default Chip;
