import * as clickhouse from "./ch-events";

class VideoArticleCardObserver {
  constructor(configOptions = {}, play, pause, trackArticleSeen = true) {
    this.options = {
      root: configOptions.root || null,
      rootMargin: configOptions.rootMargin || "0px",
      threshold: configOptions.threshold || 0.7
    };
    this.play = play;
    this.pause = pause;
    this.trackArticleSeen = trackArticleSeen;
    this.observer = new IntersectionObserver(this.handleIntersect, this.options);
    this.timerMap = new Map();
    this.visibleCards = new Set();
    this.arr = [];
  }

  observe = (targetElem) => {
    this.observer.observe(targetElem);
  };

  destroy = () => {
    this.observer.disconnect();
    this.visibleCards = new Set();
    this.timerMap = new Map();
  };

  trackVisibleCard = (id) => {
    this.visibleCards.add(id);

    clickhouse.trackVideoArticleVisible({
      page: this.slug ? "news_details" : this.page,
      data: {
        article_id: id,
        type: "video",
        news_bite_type: "NEWS_WRAP"
      }
    });
    // }
  };

  startTimer = (target) => {
    if (!this.timerMap.has(target.dataset.id) && !this.arr.includes(target.dataset.id)) {
      const timer = setTimeout(() => {
        this.markAsSeen(target.dataset.id, target.dataset.newsbitetype);
        clearTimeout(this.timerMap.get(target.dataset.id));
      }, 3000);
      if (!this.visibleCards.has(target.dataset.id)) {
        this.trackVisibleCard(target.dataset.id);
      }
      this.timerMap.set(target.dataset.id, timer);
    }
  };

  clearTimer = (target) => {
    if (this.timerMap.has(target.dataset.id)) {
      clearTimeout(this.timerMap.get(target.dataset.id));
      this.timerMap.delete(target.dataset.id);
    }
  };

  handleIntersect = (entries) => {
    entries.forEach((entry) => {
      let target = entry.target;
      if (entry.intersectionRatio >= this.options.threshold) {
        this.play(entry.target.dataset.id);
        if (this.trackArticleSeen) {
          this.startTimer(target);
        }
      } else {
        this.pause();
        this.clearTimer(target);
      }
    });
  };

  markAsSeen = async (articleId, newsBiteType) => {
    clickhouse.trackVideoArticleSeen({
      pageType: this.slug ? "news_details" : this.page,
      data: { article_id: articleId, type: "video", news_bite_type: newsBiteType }
    });
    this.arr.push(articleId);
  };
}

export default VideoArticleCardObserver;
